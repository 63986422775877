<template>
  <v-dialog
    max-width="400"
    scrollable
  >
    <template #activator="{ on: listOn, attrs: listAttrs }">
      <v-list-item
        :disabled="disabled"
        v-bind="listAttrs"
        v-on="listOn"
      >
        <v-list-item-title>
          適用ユーザーの変更
        </v-list-item-title>
      </v-list-item>
    </template>

    <template #default="dialog">
      <v-card style="max-height: 70vh; overflow-y: auto;">
        <v-card-title>適用ユーザー管理</v-card-title>
        <v-card-text>
          <v-list>
            <v-list-item
              v-for="(reportUser, index) in reportUsers"
              :key="`assigned-user-${reportUser.attributes.id}-${index}`"
            >
              <v-list-item-content>
                <v-list-item-title>
                  {{ reportUser.attributes.user.data.attributes.name }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn
                  icon
                  @click="destroy(reportUser)"
                >
                  <v-icon small>
                    {{ icons.mdiTrashCanOutline }}
                  </v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>

          <v-divider class="my-4"></v-divider>

          <v-combobox
            :value="selectedUserName"
            :items="availableUsers"
            item-text="attributes.name"
            label="新しくユーザーを紐付ける"
            placeholder="ユーザーを選択してください"
            no-data-text="利用可能なユーザーがありません"
            @focus="fetchAvailableUsers"
            @change="onUserSelect"
          />
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            @click="
              dialog.value = false
              $emit('cancel')
            "
          >
            キャンセル
          </v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import { ref, toRefs, computed } from '@vue/composition-api'
import { mdiTrashCanOutline } from '@mdi/js'

export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    reportUsers: {
      type: Array,
      default: () => [],
    },
    availableUsers: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const { availableUsers } = toRefs(props)

    const selectedUserId = ref(null)
    const loading = ref(false)

    const selectedUserName = computed(() => {
      if (!selectedUserId.value) return ''

      return availableUsers.value.find(user => user.attributes.id === selectedUserId.value)?.attributes?.name
    })

    const fetchAvailableUsers = () => {
      if (loading.value) return

      loading.value = true
      const callback = () => { loading.value = false }
      emit('fetch-available-users', callback)
    }

    const onUserSelect = item => {
      if (loading.value) return

      if (item?.attributes?.id) {
        loading.value = true
        const callback = () => {
          loading.value = false
          selectedUserId.value = null
        }

        selectedUserId.value = item.attributes.id
        emit('create', { userId: item.attributes.id, callback })
      }
    }

    const destroy = reportUser => {
      if (loading.value) return

      const callback = () => { loading.value = false }
      emit('destroy', {
        id: reportUser.attributes.id,
        userId: reportUser.attributes.userId,
        callback,
      })
    }

    return {
      selectedUserId,
      selectedUserName,

      fetchAvailableUsers,
      onUserSelect,
      destroy,

      icons: {
        mdiTrashCanOutline,
      },
    }
  },
}
</script>
